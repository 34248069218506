import React, { useRef } from 'react';

import Footer from '../components/footer';
import Navbar from '../components/navbar';
import DownloadMusic from '../components/download_music';

function Music() {
  const JoinRef = useRef(null);
  const FaqRef = useRef(null);

  return (
    <div className="App">
      <div className="joinn" ref={JoinRef}>
        <Navbar hiddenNav={true} />
        <DownloadMusic />
      </div>

      <div className="faqq" ref={FaqRef}>
        <Footer />
      </div>
    </div>
  );
}

export default Music;
