import axios from "axios";
import { config } from ".";

// A new instance of axios
const instance = axios.create({
  baseURL: `${config.restapi_endpoint}/metrics`,
  headers: {},
});

export default class Metrics {
  // View Widget
  static view(data) {
    instance.post("/view", data);
  }

  // Init Connect
  static init(data) {
    instance.post("/init", data);
  }

  // Success transaction
  static sale(data) {
    instance.post("/sale", data);
  }

  static nft(data) {
    instance.post("/nft", data);
  }
}
