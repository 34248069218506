import './index.css'

import pic1 from '../../assets/images/roadmap_images/r1.png'
import pic2 from '../../assets/images/roadmap_images/r2.png'
import pic3 from '../../assets/images/roadmap_images/r3.png'
import pic4 from '../../assets/images/roadmap_images/r4.png'
import pic5 from '../../assets/images/roadmap_images/r5.png'
import pic6 from '../../assets/images/roadmap_images/r6.png'



import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;
const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;


const data = [
    {
        num: '01',
        heading: 'A PIECE OF POP CULTURE',
        para: 'After collaborating with major House and Hip Hop artists, namely MC Flipside, BEATS BEARS is joining forces with entrepreneurs, investors, influencers and tech geniuses to introduce the most epic artwork to the NFT Space. The collection of 7923 NFTs possesses over 200 traits. Get yourself a Beats Bear, mint a Bear in June 2022.',
        img: pic1
    },
    {
        num: '02',
        heading: 'BEATS BEARS MERCHANDISING',
        para: 'The Beats Bears spread love through the power of music and beats! You can count on the Beats Bears to elevate your vibes and add joy to your life.  The Beats Bears wardrobe and the changeable heart eye comes with some exclusive merchandising.',
        img: pic2

    },
    {
        num: '03',
        heading: 'CHARITY',
        para: 'As this collection dives into the love vibe, the Beats Bears have decided to make a donation of $50,000 to a water charity for clean water for people of the world as every human deserves to drink clean water!',
        img: pic3
    }, {
        num: '04',
        heading: 'FREE TICKET TO THE LOVELAND MUSIC FESTIVAL',
        para: 'The Beats Bears will make their grandiose entrance in the Metaverse through special events in collaboration with big brands and other successful projects, as well as acquiring lands and building an entire ecosystem in the new love world. Each Beats Bear will give you access to LOVELAND FESTIVAL in the metaverse to different DJs and Musicians – the largest music festival in the metaverse.',
        img: pic4
    }, {
        num: '05',
        heading: 'HEARTS AIRDROP',
        para: 'The Beats Bears will create super hearts that will be airdropped to some of our holders. Your Beats Bears might want to give their heart away and an epic beat.  Be ready to elevate your vibes and the collective’s vibes to 528 HZ Love vibes!',
        img: pic5
    }, {
        num: '06',
        heading: 'MORE LOVE, THE MERRIER',
        para: 'This is only the beginning of  BEATS BEARS journey to spreading love through the power of music and beats! More Beats Bears will join LoveLand in their musical adventures. Holders and early members of the community will have the first priority on future drops.',
        img: pic6
    },
]

function RoadSection({ num, heading, para, img }) {
    return (

        < div className='r_row' >
            <div className='r_num'>
                {num}
                <div className='r_bullet'>
                    <div className='r_line'></div>
                </div>
            </div>
            <div className='r_content'>
                <h1>{heading}</h1>
                <p>
                    {para}
                </p>
            </div>
            <div className='r_image_wrapper'>
                <Reveal triggerOnce={true} keyframes={pulse}>
                    <img src={img} alt='' />
                </Reveal>
            </div>
        </div >

    )
}


function Roadmap() {
    return (
        <div className='roadmap_container_bg' id='roadmap'>

            <div className='roadmap_container'>
                <Reveal triggerOnce={true} keyframes={fade_}>
                    <div className='roadmap_content'>
                        <h1>ROAD MAP</h1>
                        <p>Our roadmap 1.0 will be updated according to the project's evolution.</p>
                    </div>
                </Reveal>

                <Reveal triggerOnce={true} keyframes={fade_}>

                    <div className='roadmap_sections'>
                        {
                            data.map((v, i) => {
                                return (
                                    <RoadSection key={i} heading={v.heading} num={v.num} para={v.para} img={v.img} />
                                )
                            })
                        }
                    </div>
                </Reveal>

            </div>
        </div>
    )
}

export default Roadmap;