import React, { memo, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { constants } from 'ethers';
import CryptoJS from 'crypto-js';

import { config, Metrics } from '.';

export const AppContext = React.createContext({});

export const AppContextProvider = memo(({ children }) => {
  const affiliate = useMemo(() => {
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);

      const decode = CryptoJS.AES.decrypt(searchParams.get('a').replaceAll(' ', '+'), config.project_key);

      if (!decode.toString(CryptoJS.enc.Utf8).length) {
        return constants.AddressZero.toString();
      }

      const wallet_address = decode.toString(CryptoJS.enc.Utf8);
      return wallet_address;
    }

    return constants.AddressZero.toString();
  }, []);

  const [state, setState] = useState({
    config: {
      key: config.project_key,
      infuraId: config.infuraId,
      walletConnectProjectId: config.walletConnectProjectId,
      network: config.network,
      gas_limit: config.gas_limit,
      contract_address: false,
      affiliate,
    },
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    const { project_key, restapi_endpoint } = config;
    const fetchConfig = async () => await axios.get(`${restapi_endpoint}/mint/${project_key}/config`);

    fetchConfig()
      .then((response) => {
        if (response.status === 200) {
          const { contract_settings } = response.data;

          setState((state) => ({
            ...state,
            config: {
              ...state.config,
              ...contract_settings,
            },
            isLoading: false,
          }));
        }
      })
      .then(() => Metrics.view({ project_key, affiliate }))
      .catch(() => setState((state) => ({ ...state, isError: true, isLoading: false })));
  }, [affiliate]);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
});
