import './index.css'
import img1 from "../../assets/images/collection/1.png"
import img2 from "../../assets/images/collection/3.png"
import img3 from "../../assets/images/collection/2.png"
import img4 from "../../assets/images/collection/4.png"
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";


const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;
const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;


function Collection() {
    return (
        <div className='rarity_container'>
            <div className='rarity_heading'>
                <h1>Beats Bears Collection & Rarity</h1>
                <p>The collection includes 4 sets of 2D, 3D & 1/1 NFTs with super rare traits<br />
                    and items, all completely randomized during the minting process.
                </p>
            </div>
            <div className='rarity_images'>
                <div className='sub_images'>
                    <div className='r_image_wrapper'>
                        <Reveal triggerOnce={true} keyframes={pulse}>
                            <img src={img3} alt='' />
                        </Reveal>
                    </div>

                    <div className='rarity_text'>
                        <Reveal triggerOnce={true} keyframes={fade_}>
                            <h1>7777 NFT-2D Bears</h1>
                            <p>Each NFT is generated by combining 200 traits with different rarity values.<br />
                                It includes 77 super rare (apporx 1%) “Heart Eye” bears.<br />The traits include background, skin, mouth, clothes, accessories, hair, and jewelry.
                            </p>
                        </Reveal>
                    </div>
                </div>
                <div className='sub_images'>
                    <div className='r_image_wrapper'>
                        <Reveal triggerOnce={true} keyframes={pulse}>
                            <img src={img2} alt='' />
                        </Reveal>
                    </div>
                    <div className='rarity_text'>
                        <Reveal triggerOnce={true} keyframes={fade_}>
                            <h1>27 NFT-2D Infinity Bears</h1>
                            <p>Each NFT is created manually one by one (non generated) with different rarity values.
                                It includes 27 super rare (0.05%) “Infinity Eye” bears.<br />
                                The traits include background, skin, mouth, clothes, accessories, and hair.
                            </p>
                        </Reveal>
                    </div>
                </div>
            </div>
            <div className='rarity_images'>
                <div className='sub_images'>
                    <div className='r_image_wrapper'>
                        <Reveal triggerOnce={true} keyframes={pulse}>

                            <img src={img1} alt='' />
                        </Reveal>
                    </div>
                    <div className='rarity_text'>
                        <Reveal triggerOnce={true} keyframes={fade_}>
                            <h1>111 NFT-3D Bears</h1>
                            <p>Each NFT is generated by combining 200 traits with different rarity values.<br />
                                It includes 77 super rare (1%) “Heart Eye” bears.<br />The traits include background, skin, mouth, clothes, accessories, hair, and jewelry.
                            </p>
                        </Reveal>
                    </div>
                </div>
                <div className='sub_images'>
                    <div className='r_image_wrapper'>
                        <Reveal triggerOnce={true} keyframes={pulse}>
                            <img src={img4} alt='' />
                        </Reveal>
                    </div>
                    <div className='rarity_text'>
                        <Reveal triggerOnce={true} keyframes={fade_}>
                            <h1>8-1/1 NFT-3D Maestro Bears</h1>
                            <p>Each NFT is created manually one by one (non generated) with different rarity values.<br />
                                These Legendary Bears are 1/1 NFTs inspired by all the things we love. These art pieces have no common traits with the regular collection.
                            </p>
                        </Reveal>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Collection;