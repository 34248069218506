import './index.css'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useContext } from 'react';


function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className='faq_header' onClick={decoratedOnClick}>
            <button className={isCurrentEventKey ? 'b_active': ''}>{isCurrentEventKey ? ' - ' : ' + '} </button>
            <h3>{children} </h3>
        </div>

    );
}

function Faq() {
    return (
        <div id='faq' className="faq_container">
            <h1 className='theme-heading'> FAQ </h1>
            <div>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="0">WHAT ARE THE MAESTROS</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>The Maestros are the rarest of the Beats Bears NFTs. You can get one with a lucky mint or on the secondary market only.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="1">HOW CAN I USE MY NFT?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Your Beats Bears have been created with a lot of details in 3D  ready to be used as an avatar in the metaverse. You’ll also have access to exclusive physical merchandise and huge benefits as a holder.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>


    )
}

export default Faq;