import './index.css'
import VICTORIA from '../../assets/images/team/team4.png'
import FLIPSIDE from '../../assets/images/team/team1.png'
import YOKO from '../../assets/images/team/team3.png'
import SATOSHI from '../../assets/images/team/team5.png'
import FELICIA from '../../assets/images/team/team2.png'

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fade_top = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

const fade_down = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;


function Team() {
    return (
        <div className='team_container' id='team'>
            <div className='team_heading'>
                <h1>THE TEAM</h1>
            </div>
            <div className='team_member_wrapper'>

                <div className='team_member'>
                    <Reveal triggerOnce={true} keyframes={fade_top}>

                        <div className='team_image'>
                            <img src={VICTORIA} alt='' />
                        </div>
                        <h3>VICTORIA</h3>
                        <p>Social Media</p>
                    </Reveal>
                </div>

                <div className='team_member'>
                    <Reveal triggerOnce={true} keyframes={fade_down}>

                        <div className='team_image'>
                            <img src={FLIPSIDE} alt='' />
                        </div>
                        <h3>MC FLIPSIDE</h3>
                        <p>Musician & Producer</p>
                    </Reveal>
                </div>

                <div className='team_member'>
                    <Reveal triggerOnce={true} keyframes={fade_top}>

                        <div className='team_image'>
                            <img src={YOKO} alt='' />
                        </div>
                        <h3>YOKO</h3>
                        <p>Graphics</p>
                    </Reveal>
                </div>

                <div className='team_member'>
                    <Reveal triggerOnce={true} keyframes={fade_down}>

                        <div className='team_image'>
                            <img src={SATOSHI} alt='' />
                        </div>
                        <h3>SATOSHI</h3>
                        <p>Blockchain</p>
                    </Reveal>
                </div>

                <div className='team_member'>
                    <Reveal triggerOnce={true} keyframes={fade_top}>
                        <div className='team_image'>
                            <img src={FELICIA} alt='' />
                        </div>
                        <h3>FELICIA</h3>
                        <p>Creative Freak</p>
                    </Reveal>
                </div>

            </div>
        </div>
    )
}

export default Team;