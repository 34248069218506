import "./index.css";
import { FaTwitter, FaInstagram, FaAngleUp } from "react-icons/fa";
import LOGO from "../../assets/images/logo.png";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

function Navbar({ activeSection, hiddenNav }) {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    const header = document.querySelector(".second_nav_section");
    const header2 = document.querySelector(".first_nav_section");
    const anchor = document.querySelector(".anchor_container");
    scrollTop >= 46.6
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    scrollTop >= 46.6
      ? header2.classList.add("add_padding")
      : header2.classList.remove("add_padding");
    scrollTop <= 220
      ? anchor.classList.add("display_none")
      : anchor.classList.remove("display_none");
  };

  return (
    <>
      <div className="Navbar_container">
        <div className="first_nav_section">
          <div className="nav_icons_container">
            <span
              onClick={() =>
                window.open("https://twitter.com/thebeatsbears", "_blank")
              }
            >
              <FaTwitter />
            </span>

            <span
              onClick={() =>
                window.open(
                  "https://www.instagram.com/BeatsBearsWorld/",
                  "_blank"
                )
              }
            >
              <FaInstagram />
            </span>
          </div>
        </div>
        
        <div className="second_nav_section">
          <div className="logo_container">
            <img src={LOGO} alt="" />
          </div>

          {!hiddenNav && (
            <div className="menu_container">
              <span className={activeSection === "joinn" ? "active_menu" : ""}>
                <HashLink smooth to={"#features"}>
                  FEATURES
                </HashLink>
              </span>

              <span className={activeSection === "art" ? "active_menu" : ""}>
                <HashLink smooth to={"#artist"}>
                  ARTIST
                </HashLink>
              </span>

              <span className={activeSection === "rarity" ? "active_menu" : ""}>
                <HashLink smooth to={"#rarity"}>
                  RARITY
                </HashLink>
              </span>

              <span
                className={activeSection === "road_map" ? "active_menu" : ""}
              >
                <HashLink smooth to={"#roadmap"}>
                  ROADMAP
                </HashLink>
              </span>

              <span className={activeSection === "teamm" ? "active_menu" : ""}>
                <HashLink smooth to={"#team"}>
                  TEAM
                </HashLink>
              </span>

              <span className={activeSection === "faqq" ? "active_menu" : ""}>
                <HashLink smooth to={"#faq"}>
                  FAQ
                </HashLink>
              </span>
            </div>
          )}

          <div className="nav_button_container">
            <button
              onClick={() =>
                window.open("https://x.com/TheBeatsBears", "_blank")
              }
            >
              Follow Us
            </button>
          </div>
        </div>

        <div className="anchor_container">
          <HashLink to="#" smooth className="back-to-top-button">
            <FaAngleUp />
          </HashLink>
        </div>
      </div>

      <div className="m_navbar_container">
        <img src={LOGO} alt="" />
      </div>
    </>
  );
}

Navbar.defaultValue = {
  hiddenNav: false,
};

export default Navbar;
