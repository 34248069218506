import './index.css';
import JOIN from '../../assets/images/join2.png';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const fade_left = keyframes`
    from {
        opacity: 0;
        -webkit-transform: translate3d(-50px, 0, 0);
        transform: translate3d(-50px, 0, 0);
      }
    
      to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
      }
    }
`;

function Join() {
  return (
    <div id="features" className="join_project_container">
      <div className="join_project_container_bg"></div>

      <div className="join_project_content_container">
        <Reveal triggerOnce={true} keyframes={fade_left}>
          <h1>JOIN THE PROJECT</h1>
          
          <div className="youtube_">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/72fluhivM1U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <p>
            The Beats Bears are a collection of 3D and 2D unique collectible art pieces that comes with a pumping house track. The Beats Bears NFT collection
            was created by Felicia Pizzonia. Felicia has created some cool projects while working with some of the world’s best in business. Felicia’s focus
            with the Beats Bears is to spread love through the power of pumping music and bears! Be the first to own one of the Beats Bears NFTs accompanied
            with a pumping house track called “Gratitude”. Gratitude is written, produced, and performed by MC FLIPSIDE. The Beats Bears love to dance to epic
            house music, and beats, and to spread the love! The Beats Bears NFT collection lives their best life in Loveland and are here to elevate vibes
            through the power of music and beats. Beats Bears are on their way to taking over the metaverse. Stay connected with the Beats Bears community and
            join us today!
          </p>

          <button onClick={() => window.open('https://x.com/TheBeatsBears', '_blank')}>Follow Us</button>
        </Reveal>
      </div>

      <div className="join_project_image_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={JOIN} alt="" />
        </Reveal>
      </div>
    </div>
  );
}

export default Join;
