import './index.css';
import ARTIST from '../../assets/images/artist.png';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const fade_right = keyframes`
from {
    opacity: 0;
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
`;

function Artist() {
  return (
    <div className="artist_container" id="artist">
      <div className="artist_image_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={ARTIST} alt="" />
        </Reveal>
      </div>
      <div className="artist_content_container">
        <Reveal triggerOnce={true} keyframes={fade_right}>
          <h1>THE ARTIST & MUSIC</h1>
          
          <div className="youtube_">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ROgkH9oqVpQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            The Beats Bears are a collection of 7923 hand-drawn. Bears that come with an epic house track – called GRATITUDE, written and performed by MC
            FLIPSIDE. The Beats Bears NFT collection live their best life in Loveland and are here to spread love and elevate vibes through the power of music
            and epic beats. Beats Bears are on their way to take over the metaverse.
          </p>
        </Reveal>
      </div>
    </div>
  );
}

export default Artist;
