import { mainnet, polygon } from 'wagmi/chains';

export function GetChain(network_id) {
  switch (+network_id) {
    case 1:
      return mainnet;
    case 137:
      return polygon;
    default:
      return false;
  }
}

const NETWORKS = {
  ETHEREUM_MAINNET: {
    ID: 1,
    API_URL: 'https://api.etherscan.io/',
    URL: 'https://etherscan.io/',
    CURRENCY: 'ETH',
  },
  POLYGON: {
    ID: 137,
    API_URL: 'https://api.polygonscan.com/',
    URL: 'https://polygonscan.com/',
    CURRENCY: 'MATIC',
  },
};

export const GetNetworkData = (network_id) => {
  switch (network_id) {
    case 1:
      return NETWORKS.ETHEREUM_MAINNET;
    case 137:
      return NETWORKS.POLYGON;
    default:
      return {};
  }
};

export { default as config } from './config.json';
export { default as Metrics } from './metrics';
export { AppContext, AppContextProvider } from './context';
