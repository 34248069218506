import { useContext } from 'react';
import Reveal from 'react-awesome-reveal';

import { AppContext } from '../../config';
import Mint from '../mint';
import { pulse, fade_right } from './mint_cover.styles';

import './index.css';
import HEADING from '../../assets/images/heading.png';
import BEAR from '../../assets/images/red-cap.png';

function Cover() {
  const { isLoading } = useContext(AppContext);

  return (
    <div className="mint_cover_container">
      <div className="mint_cover_bear_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={BEAR} alt="" />
        </Reveal>
      </div>

      <div className="mint_cover_content_container">
        <Reveal triggerOnce={true} keyframes={fade_right}>
          <div data-gdlr-animation-duration="600ms" data-gdlr-animation-offset="0.8" className="m_bear_heading_container">
            <img src={HEADING} alt="" />
          </div>
        </Reveal>

        {!isLoading && <Mint />}
      </div>
    </div>
  );
}

export default Cover;
