import './index.css';
import LOGO from '../../assets/images/logo.png';
import FOOTER from '../../assets/images/footer.png';

import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

function Footer() {
  return (
    <div className="footer_container_bg">
      <div className="footer_container">
        <Reveal triggerOnce={true} keyframes={fade_}>
          <div className="footer_first_section">
            <div className="footer_first_section_left">
              <div className="footer_logo_container">
                <img src={LOGO} alt="" />
              </div>

              <div className="footer_content_container">
                <p>
                  The 3D and 2D NFT Beats Bears Collection are taking over the metaverse and spreading love through music! Let’s operate in the love mode only.
                  <br />
                  Beats + Bears = LOVE! Join us and be part of this love revolution and dance with the Beats Bears in the metaverse.
                </p>
              </div>
            </div>

            <div className="footer_first_section_right">
              <span onClick={() => window.open('https://www.instagram.com/BeatsBearsWorld/', '_blank')}>Instagram</span>
              <span onClick={() => window.open('https://twitter.com/thebeatsbears', '_blank')}>Twitter</span>
            </div>
          </div>
        </Reveal>

        <div className="footer_second_section">
          <div className="sponsers_images_container">
            <img src={FOOTER} alt="" />
          </div>

          <div className="footer_credits">© 2024 BEATS BEARS. ALL RIGHTS RESERVED</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
