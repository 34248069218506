import './index.css';
import L_IMG from '../../assets/images/jd_l.png';
import R_IMG from '../../assets/images/jd_r.png';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`;

const fade_ = keyframes`
from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

function JoinDiscord() {
  return (
    <div className="join_discord_container">
      <div className="jd_img_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={L_IMG} alt="" />
        </Reveal>
      </div>

      <div className="jd_content_container">
        <Reveal triggerOnce={true} keyframes={fade_}>
          <h1>Follow Us</h1>

          <p>
            Join us and be part of an ever-growing epic community of art collectors, investors, musicians, and entrepreneurs.
            <br />
            Join our Discord to keep up with the Beats Bears
          </p>

          <button onClick={() => window.open('https://x.com/TheBeatsBears', '_blank')}>Follow Us</button>
        </Reveal>
      </div>
      
      <div className="jd_img_container">
        <Reveal triggerOnce={true} keyframes={pulse}>
          <img src={R_IMG} alt="" />
        </Reveal>
      </div>
    </div>
  );
}

export default JoinDiscord;
