import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import './index.css';
import AsNavFor from './slider';

import p1 from '../../assets/images/maestros/Do.jpg';
import p2 from '../../assets/images/maestros/Do-1.jpg';
import p3 from '../../assets/images/maestros/Fa.jpg';
import p4 from '../../assets/images/maestros/La.jpg';
import p5 from '../../assets/images/maestros/Mi.jpg';
import p6 from '../../assets/images/maestros/Re.jpg';
import p8 from '../../assets/images/maestros/So.jpg';
import p9 from '../../assets/images/maestros/Si.jpg';

function SwiperModal() {
  const [show, setShow] = useState(false);
  const [pic, setPicture] = useState(1);

  const setPictureIndex = (num) => {
    setShow(true);
    setPicture(num);
  };

  return (
    <>
      <Modal className="modal_main_container" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <div className="modal_btn_container">
          <button href="#" onClick={() => setShow(false)}></button>
        </div>

        <AsNavFor startIndex={pic} />
      </Modal>

      <div className="maestros_container">
        <div className="maestros_content">
          <h1>THE MAESTROS</h1>
          <p>
            The Maestro Bears are 1/1 NFTs inspired by the love of beats and music with a focus on spreading love. These art pieces have no common traits with
            the regular collection and come with a custom epic beat!
          </p>
        </div>

        <div className="maestros_slider">
          <Swiper
            className="mySwiper"
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
          >
            <SwiperSlide onClick={() => setPictureIndex(0)}>
              <img src={p1} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(1)}>
              <img src={p2} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(2)}>
              <img src={p3} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(3)}>
              <img src={p4} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(4)}>
              <img src={p5} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(5)}>
              <img src={p6} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(6)}>
              <img src={p8} alt="" />
            </SwiperSlide>

            <SwiperSlide onClick={() => setPictureIndex(7)}>
              <img src={p9} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default SwiperModal;
