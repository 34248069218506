import { memo } from 'react';

import { RarityHeading } from './download_music.styles';
import './index.css';

const DownloadMusic = memo(() => {
  return (
    <div className="rarity_container">
      <RarityHeading className="rarity_heading">
        <h1>
          Download your exclusive <br /> Gratitude Track by MC FLIPSIDE
        </h1>

        <a
          className="to_open_mintbox_button"
          href="https://www.dropbox.com/scl/fi/z6x0uov3apo6syb4ysh6p/Give-Thanks-Radio-Edit.wav?rlkey=0piaxsojq8memn9oaa8wmlgig&st=vl8bd3q1&dl=0"
        >
          DOWNLOAD
        </a>
      </RarityHeading>
    </div>
  );
});

export default DownloadMusic;
