/* global BigInt */
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { ConnectKitButton } from 'connectkit';
import { useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { parseEther } from 'viem';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { AppContext, Metrics } from '../../config';
import { Alert, MintButton, MintButtonQuantity, MintButtonWrapper, MintContainer, MintPrice, MintTitle } from './mint.styles';
import { Link } from 'react-router-dom';

export default function Mint() {
  const {
    config: { contract_address, abi, nft_type, ...config },
  } = useContext(AppContext);

  const { isConnected, address } = useAccount();
  const [mintAmount, setMintAmount] = useState(1);

  const koef = Array(mintAmount).fill(1.3);
  koef[0] = 1;

  const totalKoef = useMemo(() => koef.reduce((accumulator, currentValue) => accumulator * currentValue).toFixed(2), [koef]);
  const gasLimit = useMemo(() => BigInt(Math.round(+config.gas_limit * +totalKoef)), [config.gas_limit, totalKoef]);
  const args = useMemo(() => [...(nft_type === 'single' ? [] : [mintAmount]), config.affiliate], [config.affiliate, mintAmount, nft_type]);

  const {
    error: prepareError,
    isError: isPrepareError,
    config: prepareConfig,
    isLoading: isPrepareLoading,
  } = usePrepareContractWrite({
    address: contract_address,
    abi,
    functionName: 'mint',
    args,
    value: parseEther((+config.cost * mintAmount).toString()),
    gas: gasLimit,
  });

  const { data, error, isError, write } = useContractWrite(prepareConfig);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const handlePlus = useCallback(() => {
    if (config?.maxMintAmount && config.maxMintAmount > mintAmount) {
      setMintAmount(mintAmount + 1);
    }

    if (!config?.maxMintAmount) {
      setMintAmount(mintAmount + 1);
    }
  }, [config?.maxMintAmount, mintAmount, setMintAmount]);

  const handleMinus = useCallback(() => {
    if (mintAmount === 1) {
      return;
    }

    setMintAmount(mintAmount - 1);
  }, [mintAmount, setMintAmount]);

  const handleMint = async () => write?.();

  useEffect(() => {
    if (isConnected) {
      Metrics.init({ project_key: config.key, affiliate: config.affiliate });
    }

    if (isSuccess) {
      Metrics.nft({
        project_key: config.key,
        affiliate: address,
      });

      Metrics.sale({
        project_key: config.key,
        affiliate: config.affiliate,
        hash: data?.hash,
        value: parseEther((+config.cost * mintAmount).toString()).toString(),
      });
    }
  }, [address, data?.hash, isConnected, isSuccess, config.key, config.affiliate, config.cost, mintAmount]);

  return (
    <MintContainer>
      <MintTitle>Minting Is {config.paused ? 'Paused' : 'Opened'}</MintTitle>

      {isPrepareError && <Alert $variant="error">{prepareError?.shortMessage}</Alert>}

      {isError && <Alert $variant="error">{error?.shortMessage}</Alert>}

      {isSuccess ? (
        <Alert $variant="success">
          <p>
            Hey Beats Bears VIP NFT holder! Thank you for minting your exclusive Beats Bear NFT! You now have access to a private{' '}
            <Link to="/music">download link</Link> for the exclusive pumping house music track "Gratitude" by MC Flipside, available only to Beats Bear NFT
            holders!
          </p>

          <p>We hope you have a blast grooving to this epic soundtrack!</p>

          <p>
            With love and gratitude,
            <br />
            The Beats Bears Team
          </p>
        </Alert>
      ) : (
        <>
          <MintButtonWrapper>
            <MintButtonQuantity onClick={handleMinus} disabled={mintAmount === 1 || isLoading}>
              <HiMinus />
            </MintButtonQuantity>

            <div>
              <span>QUANTITY</span>
              <span>{mintAmount}</span>
            </div>

            <MintButtonQuantity onClick={handlePlus} disabled={mintAmount === config?.maxMintAmount || isLoading}>
              <HiPlus />
            </MintButtonQuantity>
          </MintButtonWrapper>

          <MintPrice>{(mintAmount * +config.cost).toFixed(2)} ETH</MintPrice>

          {!isConnected ? (
            <ConnectKitButton.Custom>{({ show }) => <MintButton onClick={show}>Connect Wallet</MintButton>}</ConnectKitButton.Custom>
          ) : (
            <MintButton disabled={config.paused || isLoading || isPrepareError || isPrepareLoading} onClick={handleMint}>
              {isLoading ? 'Minting...' : 'MINT'}
            </MintButton>
          )}
        </>
      )}
    </MintContainer>
  );
}
