import styled from 'styled-components';

import bg from '../../assets/images/bg1.jpg';

export const Alert = styled.div`
  margin: 0 0 10px;
  border-left: 3px solid transparent;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: ${({ $variant }) => ($variant === 'error' ? 'red' : 'green')};

  p {
    margin: 0 0 8px;

    a {
      text-decoration: underline !important;
    }
  }
`;

export const MintContainer = styled.div`
  background-image: url(${bg});
  background-position: center;
  border-radius: 25px;
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
  box-shadow: -38px 18px 10px 0px rgba(0, 0, 0, 0.5);
  user-select: none;
`;

export const MintTitle = styled.h2`
  font-size: 28px;
  line-height: 34px;
  color: white;
  text-align: center;
`;

export const MintButtonWrapper = styled.div`
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff;
  border-radius: 25px;
  width: 100%;
  font-weight: 500;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: white;
      font-size: 28px;
      font-weight: 700;
    }
  }
`;

export const MintButtonQuantity = styled.button`
  height: 40px;
  width: 40px;
  background-color: #5100a2;
  border: 1px solid white;
  border-radius: 20px;
  color: #fff;
  font-weight: 800 !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  &:disabled {
    opacity: 0.5;
  }
`;

export const MintPrice = styled.p`
  color: white;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.07em;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
`;

export const MintButton = styled.button`
  letter-spacing: 0.8px;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  background: transparent;
  border: 2px solid white;
  padding: 15px 90px;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover:not(:disabled) {
    animation-name: elementor-animation-wobble-skew;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    transform: scale(1.03);
  }
`;
