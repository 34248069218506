import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Home from '../pages/index';
import Music from '../pages/music';
import { memo } from 'react';

const AppRouter = memo(() => {
  return (
    <Router>
      <Switch>
        <Route path={'/music'} component={Music} />
        <Route path={'/'} component={Home} />
      </Switch>
    </Router>
  );
});

export default AppRouter;
