import { useContext } from 'react';
import { createConfig, WagmiConfig } from 'wagmi';
import { ConnectKitProvider, getDefaultConfig } from 'connectkit';

import { AppContext, GetChain } from './config';
import AppRouter from './config/router';

const chains = [];

const App = () => {
  const { config } = useContext(AppContext);

  // Add chain
  chains.push(GetChain(config.network));

  const _config = createConfig(
    getDefaultConfig({
      // Required API Keys
      ...config,

      // Required
      appName: 'Beats Bears Mint',
      chains,

      // Optional
      appDescription: 'Beats Bears Mint Widget',
    }),
  );

  return (
    <WagmiConfig config={_config}>
      <ConnectKitProvider>
        <AppRouter />
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default App;
